import React from 'react'
import Img from 'gatsby-image'

import './storyShowcase.scss'

import Button from '../button'

interface StoryShowcaseProps {
  title: string
  text: string
  date?: string
  cta: {
    link: string
    text: string
  }
  image: GatsbyTypes.GatsbyImageSharpFluidFragment
}

const StoryShowcase: React.FC<StoryShowcaseProps> = ({
  title,
  text,
  date,
  cta,
  image,
}) => (
  <div className="story-showcase">
    <div className="text-content">
      {date && <h5>{date}</h5>}
      <h1>{title}</h1>
      <p>{text}</p>
      <div className="special-button">
        <Button color="gold" text={cta.text} link={cta.link} />
      </div>
    </div>
    <Img className="about-image" fluid={image} />
  </div>
)
export default StoryShowcase
